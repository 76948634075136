@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Lato', sans-serif !important;
    font-display: swap;
}

body {
    height: 100vh;
    width: 100vw;
}

.phone_i2_class {
    height: 40px !important;
    width: 100% !important;
}
.clip-custom {
    height: 100vh;
    width: 100vw;
    -webkit-clip-path: polygon(100% 25%, 100% 100%, 0% 100%, 0% 75%);
    clip-path: polygon(100% 25%, 100% 100%, 0% 100%, 0% 75%);
}

.hc-input {
    height: 40px;
    font-size: 16px;
    padding: 8.5px;
    width: 100%;
    font-family: inherit;
    outline-color: #336cb7;
    border: 1px solid #e5e8eb;
    border-radius: 8px;
}
.react-tel-input .flag-dropdown {
    border: 1px solid #e5e8eb !important;
    border-radius: 8px 0px 0px 8px !important;
}

.hc-submit-form {
    height: 40px;
    background-color: #336cb7;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    font-family: inherit;
    cursor: pointer;
    /* width: 100%; */
    margin: 8px 0px;
}

.hc-form-label {
    font-weight: bold;
    font-size: 14px;
    opacity: 0.8;
}

.hc-input-checkbox {
    height: 16px;
    width: 16px;
    margin: 6px;
}
.info-card {
    padding: 1rem;
    height: 4rem;
    min-height: 120px;
}

.info-cards {
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
}

.hc-textarea {
    height: 120px;
    font-size: 16px;
    padding: 8px;
    width: 100%;
    font-family: inherit;
    outline-color: #336cb74d;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}
.hc-sidebar {
    overflow: hidden;
}
.hc-sidebar:hover {
    overflow-y: auto;
}

.MuiContainer-root {
    max-width: 100% !important;
}

/* .hc-main-container {
    overflow: hidden;
}
.hc-main-container:hover {
    overflow: auto;
} */

::-moz-selection {
    /* Code for Firefox */
    color: #fff;
    background: #4DB34F;
}

::selection {
    color: #fff;
    background: #4DB34F;
}
